<template>
  <div>
    <el-dialog
      :title="title"
      :visible.sync="dialogVisible"
      width="30%"
      fullscreen
      :before-close="handleClose"
    >
      <el-button @click="exportPDF" style="float: right" type="warning"
        >导出</el-button
      >
      <div ref="report">
        <div class="content" v-loading="dataLoading">
          <p class="title">学生报告文档</p>
          <div class="topTitle">
            <div class="item">
              <img src="@/assets/stu.png" alt="" />
              姓名:{{ title }}
            </div>
            <div class="item">
              <img src="@/assets/class.png" alt="" />
              班级:{{ dataInfo.grade_name }}
            </div>
            <div class="item">
              <img src="@/assets/teacher.png" alt="" />
              教师:{{ dataInfo.user_name }}
            </div>
          </div>
          <div class="lineBox">
            <div class="left">
              <p>课程：{{ dataInfo.course_name }}</p>
              <p>考试：{{ dataInfo.title }}</p>
            </div>
            <div class="right">
              <p>
                总成绩：<span v-if="dataInfo.data">{{
                  dataInfo.get_score
                }}</span>
                <span v-else>当前无法查看考试成绩</span>
              </p>
            </div>
          </div>
          <!-- <div class="progress">
            <span>{{ dataInfo.title }}</span>
            <el-progress
              :percentage="percentage"
              :color="customColors"
              :stroke-width="13"
            ></el-progress>
          </div> -->
          <div class="progress" v-if="dataInfo.data">
            <span>合计</span>
            <el-progress
              :percentage="dataInfo.accuracy"
              :color="customColors"
              :stroke-width="13"
            ></el-progress>
          </div>
          <div class="tableBox" v-if="dataInfo.data">
            <table
              border="1"
              style="
                border-collapse: collapse;
                width: 100%;
                text-align: center;
                border-color: #f79f1e;
              "
            >
              <tr>
                <th>题型</th>
                <th>正确</th>
                <th>错误</th>
                <th>已做</th>
                <th>未做</th>
                <th>总数</th>
              </tr>
              <!-- <tr>
                <td bgcolor="#fff9d8">测试</td>
                <td>1</td>
                <td>2</td>
                <td>3</td>
                <td>1</td>
                <td>2</td>
              </tr> -->
              <tr>
                <td bgcolor="#fff9d8">合计</td>
                <td>{{ dataInfo.right }}</td>
                <td>{{ dataInfo.error }}</td>
                <td>{{ dataInfo.yesdone }}</td>
                <td>{{ dataInfo.notdone }}</td>
                <td>{{ dataInfo.total }}</td>
              </tr>
            </table>
          </div>
          <div v-if="dataInfo.data">
            <div
              class="cardItem"
              v-for="(item, index) in dataInfo.data"
              :key="index"
            >
              <div class="title">
                <img src="@/assets/kaiguan.png" alt="" />
                <p>({{ index }}){{ item.type_text }}</p>
                <span>分值:{{ item.get_score }}/{{ item.score }}分</span>
              </div>
              <p v-html="item.topic"></p>
              <el-radio-group
                v-model="item.select_answer"
                v-if="item.type == 1"
              >
                <el-radio
                  v-for="(item, index) in item.select_option"
                  :key="index"
                  disabled
                  :label="item.label"
                  >{{ item.label + "." + item.value }}</el-radio
                >
              </el-radio-group>
              <el-checkbox-group
                v-model="item.select_answer"
                v-if="item.type == 2"
              >
                <el-checkbox
                  v-for="(item, index) in item.select_option"
                  :key="index"
                  :label="item.label"
                  disabled
                  >{{ item.label + "." + item.value }}</el-checkbox
                >
              </el-checkbox-group>
              <el-radio-group
                v-model="item.select_answer"
                disabled
                v-if="item.type == 3"
              >
                <el-radio label="0">错误</el-radio>
                <el-radio label="1">正确</el-radio>
              </el-radio-group>
              <div class="analysisBox">
                <div class="top">
                  <div  style="width: 50%;">
                    <p style="text-wrap: nowrap;">我的答案：</p>
                    <pre v-html="item.select_answer_text" style="margin-top: 4px;"></pre>
                    <!-- <p>{{ item.select_answer_text }}</p> -->
                  </div>
                  <div v-if="item.type != 4">
                    <p>正确答案：</p>
                    <p>{{ item.answer_text }}</p>
                  </div>
                </div>
                <div class="analysisTxt">
                  <span style="float: left;">题目解析：</span>
                  <pre v-html="item.analysis" style="margin: 4px 0px 0 80px;"></pre>
                  <!-- <span>{{ item.analysis }}</span> -->
                </div>
              </div>
            </div>
          </div>
          <p class="cantLook" v-else>
            <i class="el-icon-warning"></i>暂时无法查看做题结果
          </p>
        </div>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import * as http from "@/api/order.js";
import html2Canvas from "html2canvas";
import JsPDF from "jspdf";
export default {
  data() {
    return {
      dialogVisible: false,
      title: "",
      htmlTitle: "",
      percentage: 10,
      percentage2: 40,
      dataLoading: false,
      customColors: [
        { color: "#f56c6c", percentage: 20 },
        { color: "#e6a23c", percentage: 40 },
        { color: "#5cb87a", percentage: 60 },
        { color: "#1989fa", percentage: 80 },
        { color: "#6f7ad3", percentage: 100 },
      ],
      data: [
        {
          label: 1,
          value: 1,
        },
        {
          label: 1,
          value: 1,
        },
      ],
      select_answer: "",
      dataInfo: {},
    };
  },
  methods: {
    exportPDF() {
      // report ref
      let PDFView = this.$refs.report;
      html2Canvas(PDFView, {
        useCORS: true, //是否尝试使用CORS从服务器加载图像
        allowTaint: true,
        dpi: 300, //解决生产图片模糊
        scale: 3, //清晰度--放大倍数
      }).then((canvas) => {
        let contentWidth = canvas.width;
        let contentHeight = canvas.height;
        let pageHeight = (contentWidth / 592.28) * 841.89; // 一页pdf显示html页面生成的canvas高度;
        let leftHeight = contentHeight; //未生成pdf的html页面高度
        let position = 0; //pdf页面偏移
        //a4纸的尺寸[595.28,841.89]，html页面生成的canvas在pdf中图片的宽高
        // let imgWidth = 595.28
        let imgWidth = 560.28; //宽度
        let imgHeight = (592.28 / contentWidth) * contentHeight;
        let pageData = canvas.toDataURL("image/jpeg", 1.0);
        let PDF = new JsPDF("", "pt", "a4");
        if (leftHeight < pageHeight) {
          PDF.addImage(pageData, "JPEG", 20, 20, imgWidth, imgHeight);
        } else {
          while (leftHeight > 0) {
            PDF.addImage(pageData, "JPEG", 20, position, imgWidth, imgHeight);
            leftHeight -= pageHeight;
            position -= 841.89;
            if (leftHeight > 0) {
              PDF.addPage();
            }
          }
        }
        PDF.save(this.htmlTitle + "报告" + ".pdf"); //下载标题
      });
    },
    show(row) {
      this.dataLoading = true;
      http.bulletin({ e_id: row }).then((res) => {
        this.title = res.student_name;
        this.htmlTitle = res.user_name;
        this.dataInfo = res;
        this.dataLoading = false;
      });
      this.dialogVisible = true;
    },
    handleClose() {
      this.dialogVisible = false;
      this.dataInfo = {};
    },
  },
};
</script>
<style lang="scss" scoped>
.progress {
  width: 70%;
  margin: 20px auto;
  position: relative;
  span {
    position: absolute;
    left: -38px;
    top: -2px;
    font-size: 16px;
    text-align: right;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}
.tableBox {
  width: 90%;
  margin: 30px auto;
  table {
    th {
      background: #fff1a6;
    }
    th,
    td {
      padding: 8px;
      box-sizing: border-box;
    }
  }
}

:deep(.el-dialog__header) {
  background: #4579e9;
  padding: 20px;
  box-sizing: border-box;
  .el-dialog__headerbtn {
    top: 14px !important;
  }
  * {
    color: #fff;
  }
}
:deep(.el-radio),
:deep(.el-checkbox) {
  display: block;
  margin: 14px 0;
}
:deep(.el-upload),
:deep(.el-upload-dragger) {
  width: 100%;
}
:deep(.el-progress__text) {
  margin-left: 9px;
}
.content {
  padding: 0 10%;
  box-sizing: border-box;
  .title {
    text-align: center;
    color: rgb(67, 67, 67);
    font-size: 24px;
  }
  .topTitle {
    display: flex;
    justify-content: space-around;
    margin-top: 20px;
    .item {
      display: flex;
      align-items: center;
      font-size: 18px;
      img {
        width: 30px;
        height: 30px;
        margin-right: 10px;
      }
    }
  }
  .lineBox {
    padding: 20px;
    box-sizing: border-box;
    border: 2px solid #f1f1f1;
    border-left: none;
    border-right: none;
    margin-top: 20px;
    display: flex;
    div {
      width: 50%;
      border-left: 5px solid #ffd880;
      padding-left: 20px;
      box-sizing: border-box;
      display: flex;
      flex-direction: column;
      justify-content: center;
    }
    .left {
      font-size: 16px;
      color: #707070;
    }
    .right {
      font-size: 22px;
      span {
        color: #32cd32;
      }
    }
  }
  .cantLook {
    text-align: center;
    margin-top: 160px;
    color: rgb(136, 136, 136);
    font-size: 18px;
    display: flex;
    align-items: center;
    justify-content: center;
    i {
      color: #ffb300;
      font-size: 24px;
    }
  }
}
.cardItem {
  width: 100%;
  padding: 16px 10px;
  box-sizing: border-box;
  background: #fff;
  box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1);
  margin-bottom: 10px;
  .title {
    display: flex;
    align-items: center;
    font-size: 14px;
    margin-bottom: 10px;
    p {
      font-weight: bold;
      margin: 0 6px;
    }
    span {
      display: inline-block;
      color: #ff5900;
      font-size: 12px;
      font-weight: 400;
      padding: 4px 10px;
      box-sizing: border-box;
      background: rgba(255, 125, 0, 0.12);
      border-radius: 6px;
    }
  }
  .analysisBox {
    background-color: #ebf4ff;
    border-radius: 6px;
    padding: 10px 16px 26px 16px;
    margin-top: 15px;
    .top {
      display: flex;
      justify-content: space-around;
      border-bottom: 1px solid #dad8d8;
      padding-bottom: 10px;
      box-sizing: border-box;
      div {
        display: flex;
        // align-items: center;
        p {
          font-size: 16px;
          &:last-child {
            font-size: 22px;
            color: red;
          }
        }
        &:last-child {
          p {
            &:last-child {
              color: rgb(0, 183, 130);
            }
          }
        }
      }
    }
    .analysisTxt {
      margin-top: 10px;
      span {
        font-weight: bold;
        font-size: 15px;
        color: #707070;
        &:last-child {
          font-weight: 500;
        }
      }
    }
  }
}
</style>
