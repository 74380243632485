<template>
  <div>
    <div class="listData" v-loading="dataLoading">
      <ul>
        <el-empty description="暂无内容" v-if="!list.length"></el-empty>
        <li v-for="item in list" :key="item.id">
          <div class="left">
            <img src="@/assets/kaoshi.png" alt="" />
            考试
          </div>
          <div class="right">
            <div>
              <p>{{ item.title }}</p>
              <p>
                <span>结束时间：{{ item.end_time }}</span>
              </p>
              <p>{{ item.is_examine_text }}</p>
            </div>
            <div class="right1">
              <el-tag :type="item.status == 0 ? 'warning' : 'success'">{{
                item.is_proceed_text
              }}</el-tag>
              <el-button
                type="primary"
                size="mini"
                @click="toTest(item.id)"
                v-if="(item.a_id || item.status == 1) && item.is_paper != 1"
                >{{ item.a_id ? "继续做题" : "开始考试" }}</el-button
              >
              <el-tag v-if="item.status == 0">
                <el-statistic
                  ref="statistic"
                  @finish="hilarity"
                  format="HH:mm:ss"
                  :value="item.start_time"
                  time-indices
                >
                  <!-- title="距离考试：" -->
                </el-statistic>
              </el-tag>
              <el-button
                type="danger"
                size="mini"
                @click="lookReport(item.id)"
                v-if="item.is_paper == 1"
                >查看报告</el-button
              >
            </div>
          </div>
        </li>
      </ul>
    </div>
    <lookScoreDialog ref="lookScoreDialog" />
  </div>
</template>
<script>
import * as http from "@/api/order.js";
import lookScoreDialog from "@/components/lookScoreDialog.vue";
export default {
  data() {
    return {
      currentPage: 1,
      list: [],
      total: 0,
      pageSize: 30,
      grade_id: sessionStorage.getItem("gradeID"),
      dataLoading: false,
      deadline4: Date.now() + (new Date().setHours(23, 59, 59) - Date.now()),
    };
  },
  mounted() {
    this.getList();
  },
  components: { lookScoreDialog },
  methods: {
    lookReport(row) {
      this.$refs.lookScoreDialog.show(row);
    },
    hilarity() {
      this.getList();
    },
    getList() {
      this.dataLoading = true;
      http.examinationList({ grade_id: this.grade_id }).then((res) => {
        this.list = res.data;
        this.dataLoading = false;
      });
    },
    toTest(id) {
      // http.questionsList({id}).then((res)=>{})
      this.$router.push({
        path: "/paperPage",
        query: {
          id,
        },
      });
    },
  },
};
</script>
<style lang="scss" scoped>
.listData {
  padding-bottom: 100px;
  box-sizing: border-box;
  ul {
    padding: 16px 0 0px 0;
    box-sizing: border-box;
    border: 1px solid #e7ebf0;
    border-radius: 8px;
    li {
      padding: 10px 16px 10px 16px;
      box-sizing: border-box;
      display: flex;
      align-items: center;
      .left {
        display: flex;
        flex-direction: column;
        align-items: center;
        min-width: 85px;
        margin-right: 16px;
        font-size: 16px;
        img {
          margin-bottom: 10px;
        }
      }
      .right {
        flex: 1;
        border-bottom: 1px solid #eee;
        background: #fff;
        display: flex;
        justify-content: space-between;
        p {
          &:nth-child(1) {
            font-size: 20px;
            cursor: pointer;
          }
          &:nth-child(2) {
            font-size: 16px;
            color: #5f6368;
            margin-top: 10px;
            span {
              &:nth-child(2) {
                margin: 0 10px;
                display: inline-block;
                padding: 0 6px;
                box-sizing: border-box;
                border: 1px solid #5f6368;
                border-top: none;
                border-bottom: none;
              }
            }
          }
          &:nth-child(3) {
            color: #ff941f;
            font-size: 16px;
            margin: 10px 0 6px 0;
          }
        }
      }
      .right1 {
        display: flex;
        flex-direction: column;
        justify-content: space-around;
        align-items: center;
      }
    }
  }
}
</style>
